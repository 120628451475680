import React from "react";
import { BrowserRouter } from "react-router-dom";
import {LayoutSplashScreen} from './components/SplashScreen'
import {Routes} from './Routes'

function App({basename}) {
  return (
    /* Add high level `Suspense` in case if was not handled inside the React tree. */
    <React.Suspense fallback={<LayoutSplashScreen />}>
      {/* Override `basename` (e.g: `homepage` in `package.json`) */}
      <BrowserRouter basename={basename}>
          {/* Render routes with provided `Layout`. */}
          <Routes />
      </BrowserRouter>
    </React.Suspense>
  );
}

export default App;
