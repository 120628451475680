import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { findProfiles } from '../Crud'
import RequestModal from './CalendarRequestDialog'
import { RatingComponent } from '../components/Rating'

const MentorsPage = () => {
	const [profileData, setProfileData] = useState(false)
	const [profiles, setProfiles] = useState([])
	const [isLoading, setIsLoading] = useState()
	useEffect(() => {
		setIsLoading(true)
		findProfiles()
			.then((result) => {
				setIsLoading(false)
				if (result.status === 200) {
					setProfiles(result.data.data.entities)
				}
			})
			.catch((e) => {
				setIsLoading(false)
				console.log(e)
			})
	}, [])
	return (
		<>
			<section className='pt-6 pt-md-8 bg-dark fs-lg'>
				<div className='mx-5 mx-md-12'>
					{isLoading && (
						<div className='d-flex justify-content-center m-md-12'>
							<div
								className='spinner-border text-light'
								style={{ width: '5rem', height: '5rem' }}
								role='status'
							>
								<span className='visually-hidden'>Loading...</span>
							</div>
						</div>
					)}
					{/*begin::Row*/}
					<div className='row'>
						{/*begin::Col*/}
						{profiles.map(
							(profile, i) =>
								profile.about && (
									<div key={i} className='col-xl-4 col-lg-6 col-md-12'>
										{/*begin::Card*/}
										<div className='card card-custom gutter-b card-stretch mb-5'>
											{/*begin::Body*/}
											<div className='card-body pt-4'>
												{/*begin::User*/}
												<div className='d-flex align-items-center mb-7'>
													{/*begin::Pic*/}
													<div className='flex-shrink-0 me-4 mt-lg-0 mt-3'>
														<div
															className={`avatar avatar-xxl ${
																!profile.image_url && 'd-none'
															}`}
														>
															<img
																src={profile.image_url}
																alt='...'
																className='avatar-img rounded-circle'
															/>
														</div>
														<div
															className={`avatar avatar-xxl ${
																profile.image_url && 'd-none'
															}`}
														>
															<span className='avatar-title rounded-circle text-capitalize'>
																{profile.name?.split(' ')[0][0]}
																{profile.name?.split(' ')[1] &&
																	profile.name?.split(' ')[1][0]}
															</span>
														</div>
													</div>
													{/*end::Pic*/}
													{/*begin::Title*/}
													<div className='d-flex flex-column'>
														<span className='text-dark fw-bold text-hover-primary fs-4 mb-0 text-capitalize'>
															{profile.name}
														</span>
														<span className='text-muted fw-bold text-capitalize'>
															{profile.title}
														</span>
													</div>
													{/*end::Title*/}
												</div>
												{/*end::User*/}
												{/*begin::Desc*/}
												<p className='mb-7' style={{ textAlign: 'justify' }}>
													{profile.about}
												</p>
												{/*end::Desc*/}
												{/*begin::Info*/}
												<div className='mb-7'>
													{/* <div className='d-flex justify-content-between align-items-center'>
														<span className='text-dark-75 font-weight-bolder me-2'>
															Email:
														</span>
														<a
															href={`mailto:${profile.email}`}
															className='text-muted text-hover-primary'
														>
															{profile.email}
														</a>
													</div> */}
													<div className='d-flex justify-content-between align-items-center'>
														<span className='text-dark-75 font-weight-bolder me-2'>
															Rating:
														</span>
														<span className='text-muted text-hover-primary'>
															<RatingComponent
																readOnly={true}
																rating={profile.rating}
															/>
														</span>
													</div>
												</div>
												{/*end::Info*/}
												<div className='d-grid gap-2'>
													<Link
														to={`/user/${profile.user_id}/${profile.name}`}
														className='btn btn-primary-soft'
													>
														Book Mentor
													</Link>
													<button
														className='btn btn-primary-soft'
														type='button'
														onClick={() => setProfileData(profile)}
													>
														Request to Open Calendar
													</button>
												</div>
											</div>
											{/*end::Body*/}
										</div>
										{/*end::Card*/}
									</div>
								)
						)}
						{/*end::Col*/}
					</div>
				</div>
			</section>
			<RequestModal
				profileData={profileData}
				onHide={() => setProfileData(false)}
			/>
		</>
	)
}
export default MentorsPage
