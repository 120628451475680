import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { ButtonBase, Typography } from '@material-ui/core'
import { Col } from 'react-bootstrap'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		minWidth: 300,
		width: '100%',
	},
	image: {
		position: 'relative',
		height: 70,
		margin: 5,
		width: '95%',
		// [theme.breakpoints.down('xs')]: {
		//   width: '100% !important', // Overrides inline-style
		//   height: 100,
		// },
		'&:hover, &$focusVisible': {
			zIndex: 1,
			'& $imageBackdrop': {
				opacity: 0.15,
			},
			'& $imageMarked': {
				opacity: 0,
			},
			'& $imageTitle': {
				border: '4px solid currentColor',
			},
		},
	},
	imageClick: {
		position: 'relative',
		height: 70,
		width: '95%',
		opacity: 0.7,
		margin: 5,
		// [theme.breakpoints.down('xs')]: {
		//   width: '100% !important', // Overrides inline-style
		//   height: 100,
		// },
	},
	focusVisible: {},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#fff', //theme.palette.common.white,
	},
	imageSrc: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundSize: 'cover',
		backgroundPosition: 'center 40%',
	},
	imageBackdrop: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: '#000', //theme.palette.common.black,
		opacity: 0.4,
		// transition: theme.transitions.create('opacity'),
	},
	imageTitle: {
		position: 'relative',
		padding: `2px 2px 7px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: '#fff', //theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		// transition: theme.transitions.create('opacity'),
	},
}))

export default function TimeButton({ data, setData }) {
	const classes = useStyles()
	const [tileClick, setTileClick] = useState('')
	function processData(id, start, end) {
		setTileClick(id)
		setData(start, end)
	}
	return (
		<div className={classes.root}>
			{data.map((tile) => (
				<Col key={tile.id} sm={12} md={6} lg={6} xl={6}>
					<ButtonBase
						focusRipple
						key={tile.id}
						className={
							tileClick === tile.id ? classes.imageClick : classes.image
						}
						focusVisibleClassName={classes.focusVisible}
						onClick={() => {
							processData(tile.id, tile.start_time, tile.end_time)
						}}
						style={{ cursor: 'pointer' }}
					>
						<span
							className={classes.imageSrc}
							style={{
								backgroundColor: '#3783e7',
							}}
						/>
						<span className={classes.imageBackdrop} />
						<span className={classes.imageButton}>
							<Typography
								component='span'
								variant='subtitle1'
								color='inherit'
								className={classes.imageTitle}
							>
								{tile.start_time} - {tile.end_time}
								<span className={classes.imageMarked} />
							</Typography>
						</span>
					</ButtonBase>
				</Col>
			))}
		</div>
	)
}
