import React, { useState } from 'react'
import DateFnsUtils from '@date-io/date-fns' // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'

export default function App({ getAvailTIme, availableDates }) {
	const [selectedDate, handleDateChange] = useState()
	const today = new Date()
	let monthEnd = today.setMonth(today.getMonth() + 2)
	const handleChange = (e) => {
		availableDates.length !== 0 && handleDateChange(e)
		availableDates.length !== 0 && getAvailTIme(e)
	}
	function disableWeekends(date) {
		return !availableDates.includes(moment(date).format('YYYY-MM-DD'))
	}
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<DatePicker
				shouldDisableDate={disableWeekends}
				value={selectedDate}
				disablePast={true}
				onChange={handleChange}
				format='MM/dd/yyyy'
				emptyLabel='Select a Date'
				autoOk
				orientation='landscape'
				variant='static'
				maxDate={monthEnd}
			/>
		</MuiPickersUtilsProvider>
	)
}
