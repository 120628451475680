import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {SplashScreenProvider} from './components/SplashScreen'

const { PUBLIC_URL } = process.env;

ReactDOM.render(
  <SplashScreenProvider>
    <App basename={PUBLIC_URL} />
  </SplashScreenProvider>,
  document.getElementById('root')
);
