import React, { useState, useEffect } from 'react'
import { BookingEditDialog } from '../components/booking-edit-dialog/BookingEditDialog'
import { WizardControl } from '../components/booking-edit-dialog/wizard_control'
// import { toAbsoluteUrl } from '../components/Helpers'
import { getServiceDetailsBySlug } from '../Crud'
import queryString from 'query-string'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'

export function LandingPage({ match }) {
	const [details, setDetails] = useState()
	const [user, setUser] = useState()
	const [actionLoading, setActionLoading] = useState(true)
	const [show, setShow] = useState(false)
	const [error, setError] = useState(false)
	useEffect(() => {
		if (match.params.service_location_slug) {
			setActionLoading(true)
			getServiceDetailsBySlug(match.params.service_location_slug)
				.then((response) => {
					setDetails(response.data.data)
					setError(response?.data?.message)
					setActionLoading(false)
				})
				.catch(({ response }) => {
					setActionLoading(false)
					setError(response?.data?.message)
				})
		}
		if (match.params.user_id) {
			setUser({ id: match.params.user_id, name: match.params.name })
			setShow(true)
		}
	}, [match.params])
	let queries = queryString.parse(window.location.search)

	useEffect(() => {
		queries.session_id &&
			Swal.fire(
				'Congratulations',
				'Booking created successfully. Details has been sent to you E-mail',
				'success'
			)
	}, [queries.session_id])
	return (
		<>
			<WizardControl />
			<BookingEditDialog
				show={show}
				setShow={setShow}
				business_slug={match.params.business_slug}
				service_location_slug={match.params.service_location_slug}
				details={details}
				user={user}
				actionLoading={actionLoading}
				error={error}
			/>
			<section className='pt-4 pt-md-11'>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-12 col-md-5 col-lg-6 order-md-2'>
							{/* Image */}
							<img
								src='/assets/img/illustrations/illustration-2.png'
								className='img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0'
								alt='...'
								data-aos='fade-up'
								data-aos-delay='100'
							/>
						</div>
						<div
							className='col-12 col-md-7 col-lg-6 order-md-1'
							data-aos='fade-up'
						>
							{match.params.service_location_slug && actionLoading ? (
								<div
									className='spinner-border m-auto my-5'
									style={{ width: '3rem', height: '3rem' }}
									role='status'
								>
									<span className='visually-hidden'>Loading...</span>
								</div>
							) : (
								<>
									{/* Heading */}
									<h1 className='display-3 text-center text-md-start'>
										{details ? (
											<span>
												Create New Booking With{' '}
												<span className='text-primary'>
													{details.staff_name}{' '}
												</span>
												for{' '}
												<span className='text-primary'>{details.service} </span>
												at{' '}
												<span className='text-primary'>
													{details.location}{' '}
												</span>
											</span>
										) : (
											<span>
												Book A <span className='text-primary'>Mentor.</span>{' '}
												<br />
												Anywhere in the World.
											</span>
										)}
									</h1>

									{/* Text */}
									<p className='lead text-center text-md-start text-muted mb-6 mb-lg-8'>
										{details ? (
											details.description
										) : (
											<>
												BookMe is an Online hourly appointment scheduling
												application that helps you manage your availability.
												Eliminates back and forth emails with Mentors. Mentees'
												bookings go straight into your calendar with a single
												click.
											</>
										)}
									</p>

									{/* Buttons */}
									<div className='text-center text-md-start'>
										<button
											type='button'
											onClick={() => setShow(true)}
											className='btn btn-primary shadow lift mx-1'
										>
											Make A Booking
											<i className='fe fe-arrow-right d-none d-md-inline ms-3'></i>
										</button>
										<Link
											to='/mentors'
											className='btn btn-primary-soft lift mx-1'
										>
											View All Mentors
										</Link>
									</div>
								</>
							)}
						</div>
					</div>{' '}
					{/* / .row */}
				</div>{' '}
				{/* / .container */}
			</section>

			{/* FEATURES */}
			<section className='py-8 py-md-11 border-bottom'>
				<div className='container'>
					<div className='row'>
						<div className='col-12 col-md-4' data-aos='fade-up'>
							{/* Icon */}
							<div className='icon text-primary mb-3'>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									xmlns='http://www.w3.org/2000/svg'
								>
									<g fill='none' fillRule='evenodd'>
										<path d='M0 0h24v24H0z' />
										<path
											d='M7 3h10a4 4 0 110 8H7a4 4 0 110-8zm0 6a2 2 0 100-4 2 2 0 000 4z'
											fill='#335EEA'
										/>
										<path
											d='M7 13h10a4 4 0 110 8H7a4 4 0 110-8zm10 6a2 2 0 100-4 2 2 0 000 4z'
											fill='#335EEA'
											opacity='.3'
										/>
									</g>
								</svg>{' '}
							</div>

							{/* Heading */}
							<h3>Flexible Availability</h3>

							{/* Text */}
							<p className='text-muted mb-6 mb-md-0'>
								Mentors set a flexible availability for the service they
								provide. Mentees can book a Mentor within a 12hours Notice.
							</p>
						</div>
						<div
							className='col-12 col-md-4'
							data-aos='fade-up'
							data-aos-delay='50'
						>
							{/* Icon */}
							<div className='icon text-primary mb-3'>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									xmlns='http://www.w3.org/2000/svg'
								>
									<g fill='none' fillRule='evenodd'>
										<path d='M0 0h24v24H0z' />
										<path
											d='M5.5 4h4A1.5 1.5 0 0111 5.5v1A1.5 1.5 0 019.5 8h-4A1.5 1.5 0 014 6.5v-1A1.5 1.5 0 015.5 4zm9 12h4a1.5 1.5 0 011.5 1.5v1a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-1a1.5 1.5 0 011.5-1.5z'
											fill='#335EEA'
										/>
										<path
											d='M5.5 10h4a1.5 1.5 0 011.5 1.5v7A1.5 1.5 0 019.5 20h-4A1.5 1.5 0 014 18.5v-7A1.5 1.5 0 015.5 10zm9-6h4A1.5 1.5 0 0120 5.5v7a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-7A1.5 1.5 0 0114.5 4z'
											fill='#335EEA'
											opacity='.3'
										/>
									</g>
								</svg>{' '}
							</div>

							{/* Heading */}
							<h3>Easy Booking</h3>

							{/* Text */}
							<p className='text-muted mb-6 mb-md-0'>
								Mentees can make bookings in a few steps. Mentors decide what
								date and time a Mentee can book their time.
							</p>
						</div>
						<div
							className='col-12 col-md-4'
							data-aos='fade-up'
							data-aos-delay='100'
						>
							{/* Icon */}
							<div className='icon text-primary mb-3'>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									xmlns='http://www.w3.org/2000/svg'
								>
									<g fill='none' fillRule='evenodd'>
										<path d='M0 0h24v24H0z' />
										<path
											d='M17.272 8.685a1 1 0 111.456-1.37l4 4.25a1 1 0 010 1.37l-4 4.25a1 1 0 01-1.456-1.37l3.355-3.565-3.355-3.565zm-10.544 0L3.373 12.25l3.355 3.565a1 1 0 01-1.456 1.37l-4-4.25a1 1 0 010-1.37l4-4.25a1 1 0 011.456 1.37z'
											fill='#335EEA'
										/>
										<rect
											fill='#335EEA'
											opacity='.3'
											transform='rotate(15 12 12)'
											x='11'
											y='4'
											width='2'
											height='16'
											rx='1'
										/>
									</g>
								</svg>{' '}
							</div>

							{/* Heading */}
							<h3>Instant Payment</h3>

							{/* Text */}
							<p className='text-muted mb-0'>
								BookMe let mentors set their payment options. Mentors get paid
								instantly when a session is completed.
							</p>
						</div>
					</div>{' '}
					{/* / .row */}
				</div>{' '}
				{/* / .container */}
			</section>
		</>
	)
}
