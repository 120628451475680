import React, { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { Modal, Row } from 'react-bootstrap'
import { WizardControl } from './wizard_control'
import './wizard-3.css'
import ButtonBase from './button'
import TimeButton from './timeButton'
import DateButton from './dateButton'
import {
	findLocations,
	getAllServices,
	findServiceLocations,
	getStaffAvailability,
	findSettings,
	createABooking,
	checkStaffDateAvailabilities,
	getOneCoupon,
	findTaxrates,
} from '../../Crud'
import moment from 'moment'
import ScrollArea from 'react-scrollbar'
import './_wizard.scss'
import * as Yup from 'yup'
import { usePaystackPayment } from 'react-paystack'
import Swal from 'sweetalert2'
import countries from '../../countries.json'
import RequestModal from '../../pages/CalendarRequestDialog'

export function WizardPage({ business_slug, user, onHide }) {
	const [profileData, setProfileData] = useState(false)
	const [nextButton, setNextButton] = useState(true)
	const [nextLoading, setNextLoading] = useState(false)
	const [booking, setBooking] = useState({})
	const [summary, setSummary] = useState({})
	const [locations, setLocations] = useState([])
	const [services, setServices] = useState([])
	const [staffs, setStaffs] = useState([])
	const [times, setTimes] = useState([])
	const [availableDates, setAvailableDates] = useState([])
	const [settings, setSettings] = useState({})
	const [loading, setLoading] = useState(false)
	const [couponPrice, setCouponPrice] = useState()
	const [couponCode, setCouponCode] = useState()
	const [couponLoading, setCouponLoading] = useState(false)
	const [openSetStaff, setOpenSetStaff] = useState()
	const [states, setStates] = useState()
	const customer_details = {
		firstname: '',
		lastname: '',
		email: '',
		phone_number: '',
		coupon_code: '',
		country: '',
		state: '',
	}

	const schema = Yup.object().shape({
		firstname: Yup.string().required().label('Firstname'),
		email: Yup.string().email().required().label('Email'),
		lastname: Yup.string().label('Lastname'),
		phone_number: Yup.string().label('Phone Number'),
		country: Yup.string().required().label('Country'),
		state: Yup.string().required().label('State'),
	})

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: customer_details,
		validationSchema: schema,
		onSubmit: (values) => {
			if (values.coupon_code) {
				values.business_id = booking.business_id
				values.start_time = new Date(booking.date + ' ' + booking.start_time)
				setCouponLoading(true)
				getOneCoupon(values)
					.then((response) => {
						if (response.status === 200) {
							setCouponPrice(response.data.data.price)
							setCouponCode(response.data.data.id)
						} else throw response
					})
					.catch(({ response }) => {
						Swal.fire(
							'error',
							response.data?.message || 'Error validating the coupon code',
							'error'
						)
						setCouponLoading(false)
					})
			}
		},
	})
	useEffect(() => {
		if (formik.values.country) {
			formik.setFieldValue('state', '')
			findTaxrates({
				filter: {
					status: true,
					country: formik.values.country,
					business_id: booking.business_id,
				},
			})
				.then((res) => {
					setStates(res.data?.data?.entities)
				})
				.catch((e) => setStates([]))
			formik.setFieldValue('state', undefined)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.country])
	const selectedState = useMemo(() => {
		const res = states?.find((s) => s.state === formik.values.state)
		if (res && !res.inclusive)
			formik.setFieldValue('tax_rate_id', res.tax_rate_id)
		return res
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.state, states])
	const amountToPay = useMemo(() => {
		let res = booking.charge
		if (couponPrice) res -= couponPrice
		if (res > 0) {
			booking.service_charge = (res * 2) / 100
			res += booking.service_charge
			booking.stripe_charge = 0.3 + (res * 2.9) / 100
			res += booking.stripe_charge
			if (selectedState && !selectedState.inclusive) {
				booking.tax = (selectedState.percentage * res) / 100
				res += (selectedState.percentage * res) / 100
			}
		}

		return res > 0 ? res : 0
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [booking.charge, selectedState, couponPrice])
	useEffect(() => {
		let unmounted = false
		findLocations(business_slug)
			.then(({ data: { data } }) => {
				if (!unmounted) {
					setLocations(data.entities)
				}
			})
			.catch((error) => console.log(error))
		return () => {
			unmounted = true
		}
		// eslint-disable-next-line
	}, [business_slug])

	//get params
	const setLocation = (res) => {
		setBooking({
			...booking,
			location_id: res.id,
			business_id: res.business_id,
		})
		setSummary({ ...summary, location: res.name })
		setNextLoading(true)
		const queryParams = {
			filter: {
				location_id: res.id,
				business_id: res.business_id,
			},
		}
		if (user?.id) queryParams.filter.staff_id = user.id
		getAllServices(queryParams).then(({ data: { data } }) => {
			setServices(data.entities)
			setNextButton(false)
			setNextLoading(false)
		})
		const query = {
			business_id: res.business_id,
			filter: [
				'STRIPE_PUBLISHABLE_KEY',
				'PAYSTACK_PUBLIC_KEY',
				'CURRENCY',
				'TIMEZONE',
			],
		}
		findSettings(query).then((response) => setSettings(response.data.data))
	}

	const setService = (res) => {
		setBooking({
			...booking,
			service_id: res.id,
			duration: res.duration,
			charge: res.charge,
		})
		setSummary({ ...summary, service: res.name, charge: res.charge })
		setNextLoading(true)
		if (user?.id) {
			setOpenSetStaff(true)
			// setStaff(user)
		} else {
			const queryParams = {
				filter: {
					location_id: booking.location_id,
					service_id: res.id,
					business_id: booking.business_id,
				},
			}
			findServiceLocations(queryParams)
				.then(({ data: { data } }) => {
					setStaffs(data.entities)
					setNextButton(false)
					setNextLoading(false)
				})
				.catch(({ response }) =>
					Swal.fire('Error', response.data.message, 'error')
				)
		}
	}
	useEffect(() => {
		if (openSetStaff) setStaff(user)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openSetStaff])

	const setStaff = (res) => {
		setBooking({ ...booking, staff_id: res.id })
		setSummary({ ...summary, staff: res.name })
		const queryParams = {
			filter: {
				staff_id: res.id,
				business_id: booking.business_id,
			},
		}
		setTimes([])
		checkStaffDateAvailabilities(queryParams).then(({ data: { data } }) => {
			setAvailableDates(data)
			setNextButton(false)
			setNextLoading(false)
		})
	}
	const getAvailTIme = (date) => {
		setBooking({ ...booking, date: moment(date).format('L') })
		setNextLoading(true)
		getStaffAvailability({
			date: moment(date).format('L'),
			staff_id: booking.staff_id,
			duration: booking.duration,
			business_id: booking.business_id,
		})
			.then(({ data: { data } }) => {
				let staff_availabities = []
				data.map((item, index) => {
					staff_availabities.push({
						id: index,
						start_time: moment.utc(item.start_time).format('h:mm a'),
						end_time: moment.utc(item.end_time).format('h:mm a'),
					})
					return true
				})
				setTimes(staff_availabities)
				setNextLoading(false)
			})
			.catch(({ response }) => {
				console.log(response)
			})
	}
	const setTime = (start, end) => {
		setBooking({ ...booking, start_time: start, end_time: end })
		setNextButton(false)
	}
	//paystack implementation
	const config = {
		reference: new Date().getTime(),
		email: formik.values.email,
		amount: amountToPay * 100,
		publicKey: settings?.PAYSTACK_PUBLIC_KEY,
	}

	const submitPayment = (payment) => {
		if (
			Object.keys(formik.touched).length === 0 ||
			Object.keys(formik.errors).length !== 0
		)
			return Swal.fire('Oops', 'Please fill all the required fields', 'warning')
		booking.customer = { ...formik.values }
		if (couponCode) payment.coupon_id = couponCode
		if (selectedState && !selectedState.inclusive)
			payment.tax_rate_id = selectedState.tax_rate_id
		setLoading(true)
		payment.country = formik.values.country
		payment.state = formik.values.state
		createABooking({ booking, payment })
			.then((response) => {
				setLoading(false)
				if (response.data?.data?.url)
					window.location.href = response.data.data.url
				else {
					onHide()
					Swal.fire(
						'Congratulations',
						response.data.message + '. Details has been sent to you E-mail',
						'success'
					)
				}
			})
			.catch(({ response }) => {
				setLoading(false)
				Swal.fire('Oops', response?.data?.message, 'error')
			})
	}
	const onSuccess = (reference) => {
		// Implementation for whatever you want to do with reference and after success call.
		let data = {
			id: booking.id,
			reference,
			payment_type: 'paystack',
		}
		setLoading(true)
		submitPayment(data)
	}
	// you can call this function anything
	const onClose = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
		console.log('closed')
	}
	const initializePayment = usePaystackPayment(config)

	const getInputClasses = (fieldname) => {
		if (formik.errors[fieldname]) {
			return 'is-invalid'
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return 'is-valid'
		}

		return ''
	}
	return (
		<>
			<Modal.Body className='overlay overlay-block cursor-default py-0'>
				{loading && (
					<div className='overlay-layer bg-transparent'>
						<div className='spinner spinner-lg spinner-success' />
					</div>
				)}
				<WizardControl />
				<div className='d-flex flex-column-fluid'>
					{/* <!--begin::Container--> */}
					<div className='container'>
						<div className='card card-custom'>
							<div className='card-body p-0'>
								{/* <!--begin: Wizard--> */}
								<div
									className='wizard wizard-3'
									id='kt_wizard_v3'
									data-wizard-state='step-first'
								>
									{/* <!--begin: Wizard Nav--> */}
									<div className='wizard-nav'>
										<div className='wizard-steps px-8 py-8 px-lg-5 py-lg-1'>
											{/* <!--begin::Wizard Step 1 Nav--> */}
											<div
												className='wizard-step'
												data-wizard-type='step'
												data-wizard-state='current'
											>
												<div className='wizard-label'>
													<h3 className='wizard-title'>
														<span>1.</span>Location
													</h3>
													<div className='wizard-bar'></div>
												</div>
											</div>
											{/* <!--end::Wizard Step 1 Nav--> */}
											{/* <!--begin::Wizard Step 2 Nav--> */}
											<div className='wizard-step' data-wizard-type='step'>
												<div className='wizard-label'>
													<h3 className='wizard-title'>
														<span>2.</span>Service
													</h3>
													<div className='wizard-bar'></div>
												</div>
											</div>
											{/* <!--end::Wizard Step 2 Nav--> */}
											{/* <!--begin::Wizard Step 3 Nav--> */}
											{!user && (
												<div className='wizard-step' data-wizard-type='step'>
													<div className='wizard-label'>
														<h3 className='wizard-title'>
															<span>3.</span>Staff
														</h3>
														<div className='wizard-bar'></div>
													</div>
												</div>
											)}
											{/* <!--end::Wizard Step 3 Nav--> */}
											{/* <!--begin::Wizard Step 4 Nav--> */}
											<div className='wizard-step' data-wizard-type='step'>
												<div className='wizard-label'>
													<h3 className='wizard-title'>
														<span>{user ? '3.' : '4.'}</span>Date & Time
													</h3>
													<div className='wizard-bar'></div>
												</div>
											</div>
											{/* <!--end::Wizard Step 4 Nav--> */}
											{/* <!--begin::Wizard Step 5 Nav--> */}
											<div className='wizard-step' data-wizard-type='step'>
												<div className='wizard-label'>
													<h3 className='wizard-title'>
														<span>{user ? '4.' : '5.'}</span>Summary
													</h3>
													<div className='wizard-bar'></div>
												</div>
											</div>
											{/* <!--end::Wizard Step 5 Nav--> */}
											{/* <!--begin::Wizard Step 6 Nav--> */}
											<div className='wizard-step' data-wizard-type='step'>
												<div className='wizard-label'>
													<h3 className='wizard-title'>
														<span>{user ? '5.' : '6.'}</span>Payment
													</h3>
													<div className='wizard-bar'></div>
												</div>
											</div>
											{/* <!--end::Wizard Step 6 Nav--> */}
										</div>
									</div>
									{/* <!--end: Wizard Nav--> */}
									{/* <!--begin: Wizard Body--> */}
									<div className='row justify-content-center py-2 px-8 px-lg-5'>
										<div className='col-xl-12 col-xxl-12'>
											{/* <!--begin: Wizard Form--> */}
											{/* <form className="form" id="kt_form"> */}
											{/* <!--begin: Wizard Step 1--> */}
											<div
												className='pb-5'
												data-wizard-type='step-content'
												data-wizard-state='current'
											>
												<h4 className='mb-2 font-weight-bold text-dark'>
													Select Your Preferred Location
												</h4>
												<ScrollArea style={{ maxHeight: '350px' }}>
													<Row>
														<ButtonBase
															data={locations}
															setData={setLocation}
														/>
													</Row>
												</ScrollArea>
											</div>
											{/* <!--end: Wizard Step 1--> */}
											{/* <!--begin: Wizard Step 2--> */}
											<div className='pb-5' data-wizard-type='step-content'>
												<h4 className='mb-2 font-weight-bold text-dark'>
													Select a Service
												</h4>
												<ScrollArea style={{ maxHeight: '350px' }}>
													<Row>
														<ButtonBase data={services} setData={setService} />
													</Row>
												</ScrollArea>
											</div>
											{/* <!--end: Wizard Step 2--> */}
											{/* <!--begin: Wizard Step 3--> */}
											{!user && (
												<div className='pb-5' data-wizard-type='step-content'>
													<h4 className='mb-2 font-weight-bold text-dark'>
														Select your Preferred Staff
													</h4>
													<ScrollArea style={{ maxHeight: '350px' }}>
														<Row>
															<ButtonBase data={staffs} setData={setStaff} />
														</Row>
													</ScrollArea>
												</div>
											)}
											{/* <!--end: Wizard Step 3--> */}
											{/* <!--begin: Wizard Step 4--> */}
											<div className='pb-5' data-wizard-type='step-content'>
												<h4 className='mb-2 font-weight-bold text-dark'>
													Select Your Preferred Date and Time
												</h4>
												<ScrollArea style={{ maxHeight: '350px' }}>
													<div className='row'>
														<div className='col-lg-6'>
															<DateButton
																getAvailTIme={getAvailTIme}
																availableDates={availableDates}
															/>
														</div>
														<div className='col-lg-6'>
															<TimeButton data={times} setData={setTime} />
															{booking.date && times.length === 0 && (
																<div className='container my-3'>
																	<strong>Period(s): Unavailable!!! </strong>
																	Please select a new date.{' '}
																</div>
															)}
															{!availableDates?.length ||
															(booking.date && times.length === 0) ? (
																<button
																	type='button'
																	className='btn btn-primary-soft'
																	onClick={() =>
																		setProfileData({
																			user_id: booking.staff_id,
																			business_id: booking.business_id,
																			duration: booking.duration,
																		})
																	}
																>
																	Request to Open Calendar
																</button>
															) : (
																<></>
															)}
														</div>
													</div>
												</ScrollArea>
											</div>
											{/* <!--end: Wizard Step 4--> */}
											{/* <!--begin: Wizard Step 5--> */}
											<div className='pb-5' data-wizard-type='step-content'>
												<ScrollArea style={{ maxHeight: '300px' }}>
													<div className='container'>
														<h3 className='mb-2 font-weight-bold text-dark text-center mb-5'>
															Confirm Your Booking Summary
														</h3>
														<div
															className='row fw-bolder'
															style={{ fontSize: '16px' }}
														>
															<div className='col-lg-6 mb-5'>
																<div className='row'>
																	<div className='text-muted col-3'>
																		Location:
																	</div>
																	<div className='font-weight-bold col-9'>
																		{summary.location}
																	</div>
																</div>
																<div className='row'>
																	<div className='text-muted col-3'>
																		Service:
																	</div>
																	<div className='font-weight-bold col-9'>
																		{summary.service}
																	</div>
																</div>
																<div className='row'>
																	<div className='text-muted col-3'>Staff:</div>
																	<div className='font-weight-bold col-8'>
																		{summary.staff}
																	</div>
																</div>
																<div className='row'>
																	<div className='text-muted col-3'>
																		Timezone:
																	</div>
																	<div className='font-weight-bold col-8'>
																		{settings.TIMEZONE}
																	</div>
																</div>
															</div>
															<div className='col-lg-6 mb-5'>
																<div className='row'>
																	<div
																		className='col-4 text-muted '
																		style={{ textAlign: 'right' }}
																	>
																		Date:
																	</div>
																	<div className='font-weight-bold col-8'>
																		{booking.date}
																	</div>
																</div>
																<div className='row'>
																	<div
																		className='col-4 text-muted '
																		style={{ textAlign: 'right' }}
																	>
																		Start Time:
																	</div>
																	<div className='font-weight-bold col-8'>
																		{booking.start_time}
																	</div>
																</div>
																<div className='row'>
																	<div
																		className='col-4 text-muted '
																		style={{ textAlign: 'right' }}
																	>
																		End Time:
																	</div>
																	<div className='font-weight-bold col-8'>
																		{booking.end_time}
																	</div>
																</div>
																<div className='row'>
																	<div
																		className='col-4 text-muted '
																		style={{ textAlign: 'right' }}
																	>
																		Total Charge:
																	</div>
																	<div className='font-weight-bold col-8'>
																		{settings.CURRENCY}
																		{booking.charge}
																	</div>
																</div>
															</div>
														</div>
														<hr />
														<div
															className='form-check'
															style={{ fontSize: '16px' }}
														>
															<input
																className='form-check-input'
																onChange={(e) =>
																	e.target.checked
																		? setNextButton(false)
																		: setNextButton(true)
																}
																type='checkbox'
																id='flexCheckDefault'
															/>
															<label
																className='form-check-label'
																htmlFor='flexCheckDefault'
															>
																I acknowlege that the details above is correct
															</label>
														</div>
													</div>
												</ScrollArea>
											</div>
											{/* <!--end: Wizard Step 5--> */}
											{/* <!--begin: Wizard Step 5--> */}
											<div className='pb-5' data-wizard-type='step-content'>
												<div className='row'>
													<div className='row'>
														<div className='col-6'>
															<label>Enter First Name</label>
															<input
																{...formik.getFieldProps('firstname')}
																type='text'
																className={`form-control mb-5 ${getInputClasses(
																	'firstname'
																)}`}
																placeholder='Please type your first name* (Required)'
															/>
														</div>
														<div className='col-6'>
															<label>Enter Last Name</label>
															<input
																{...formik.getFieldProps('lastname')}
																type='text'
																className={`form-control mb-5 ${getInputClasses(
																	'lastname'
																)}`}
																placeholder='Please type your last name'
															/>
														</div>
														<div className='col-6'>
															<label>Enter Email</label>
															<input
																{...formik.getFieldProps('email')}
																type='email'
																className={`form-control mb-5 ${getInputClasses(
																	'email'
																)}`}
																placeholder='Please type a valid email address* (Required)'
															/>
														</div>
														<div className='col-6'>
															<label>Enter Phone</label>
															<input
																{...formik.getFieldProps('phone_number')}
																type='text'
																className={`form-control mb-5 ${getInputClasses(
																	'phone_number'
																)}`}
																placeholder='Please type your phone number'
															/>
														</div>
														<div className='col-6'>
															<label>Select Country</label>
															<select
																className={`form-select mb-5 ${getInputClasses(
																	'country'
																)}`}
																required
																{...formik.getFieldProps('country')}
															>
																<option>-- Select One --</option>
																{countries.map((country, key) => (
																	<option key={key} value={country.value}>
																		{country.label}
																	</option>
																))}
															</select>
														</div>
														<div className='col-6'>
															<label>State</label>
															{states && states.length ? (
																<select
																	className={`form-select mb-5 ${getInputClasses(
																		'state'
																	)}`}
																	placeholder='State'
																	{...formik.getFieldProps('state')}
																	required
																>
																	<option value=''>-- Select State --</option>
																	{states.map((state) => (
																		<option
																			key={state.state}
																			value={state.state}
																		>
																			{state.state}
																		</option>
																	))}
																</select>
															) : (
																<input
																	type='text'
																	className={`form-control mb-5 ${getInputClasses(
																		'state'
																	)}`}
																	placeholder='State'
																	{...formik.getFieldProps('state')}
																	required
																/>
															)}
														</div>
														<div className='container'>
															<div className='form-group row'>
																<div
																	className='col-md-3 d-flex align-items-center'
																	style={{ textAlign: 'right' }}
																>
																	<label className='font-weight-bolder position-absolute right-0'>
																		Apply Coupon
																	</label>
																</div>
																<div className='col-md-7'>
																	<div className='input-group'>
																		<input
																			{...formik.getFieldProps('coupon_code')}
																			type='text'
																			className={`form-control ${getInputClasses(
																				'coupon_code'
																			)}`}
																			placeholder='Coupon Code'
																		/>
																		<div className='input-group-append'>
																			<button
																				className='btn btn-secondary'
																				type='button'
																				disabled={couponLoading}
																				onClick={formik.handleSubmit}
																			>
																				{couponPrice ? (
																					<span className='fe fe-check'></span>
																				) : couponLoading ? (
																					<div
																						className='spinner-border text-primary'
																						role='status'
																					>
																						<span className='visually-hidden'>
																							Loading...
																						</span>
																					</div>
																				) : (
																					`Apply`
																				)}
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													{/* {getInputClasses("firstname") === 'is-valid' && getInputClasses("email") === 'is-valid' && */}
													<div className='row justify-content-center align-items-center mt-5'>
														<h4 className='mb-3 font-weight-bold text-dark text-center'>
															<div>
																<strong>Total Booking Charge: </strong>
																{booking.charge?.toLocaleString('en-US', {
																	style: 'currency',
																	currency: settings.CURRENCY || 'USD',
																})}
															</div>
															<div>
																<strong>Stripe Charge: </strong>
																{booking.stripe_charge?.toLocaleString(
																	'en-US',
																	{
																		style: 'currency',
																		currency: settings.CURRENCY || 'USD',
																	}
																)}
															</div>
															<div>
																<strong>Service Charge (2%): </strong>
																{booking.service_charge?.toLocaleString(
																	'en-US',
																	{
																		style: 'currency',
																		currency: settings.CURRENCY || 'USD',
																	}
																)}
															</div>
															{selectedState && (
																<div>
																	<strong>
																		{selectedState.name} (
																		{selectedState.percentage}%):{' '}
																	</strong>
																	{booking.tax?.toLocaleString('en-US', {
																		style: 'currency',
																		currency: settings.CURRENCY || 'USD',
																	})}
																</div>
															)}
															{couponPrice && (
																<div>
																	<strong>Coupon Price: </strong>
																	{couponPrice.toLocaleString('en-US', {
																		style: 'currency',
																		currency: settings.CURRENCY || 'USD',
																	})}
																</div>
															)}
															<div>
																<strong>Total to Pay: </strong>
																{amountToPay.toLocaleString('en-US', {
																	style: 'currency',
																	currency: settings.CURRENCY || 'USD',
																})}
															</div>
															Choose a payment method:
														</h4>
														{amountToPay > 0 &&
															settings?.STRIPE_PUBLISHABLE_KEY &&
															settings?.STRIPE_PUBLISHABLE_KEY !== '' && (
																<button
																	className='col-md-4 text-center'
																	onClick={() =>
																		submitPayment({
																			payment_type: 'stripe',
																			amount: amountToPay,
																		})
																	}
																>
																	Continue payment with{' '}
																	<img
																		onClick={() =>
																			submitPayment({
																				payment_type: 'stripe',
																				amount: amountToPay,
																			})
																		}
																		style={{ width: '5rem', cursor: 'pointer' }}
																		alt='Stripe'
																		src='/assets/img/logos/stripe_logo.png'
																	></img>
																</button>
															)}
														{amountToPay > 0 &&
															settings?.PAYSTACK_PUBLIC_KEY &&
															settings?.PAYSTACK_PUBLIC_KEY !== '' && (
																<div className='col-md-4 text-center'>
																	<img
																		onClick={() =>
																			formik.values.email &&
																			getInputClasses('email') !==
																				'is-invalid' &&
																			formik.values.firstname &&
																			getInputClasses('firstname') !==
																				'is-invalid' &&
																			initializePayment(onSuccess, onClose)
																		}
																		style={{ width: '5rem', cursor: 'pointer' }}
																		alt='Paystack'
																		src='/assets/img/logos/paystack_logo.png'
																	></img>
																</div>
															)}
														{amountToPay === 0 && (
															<button
																type='button'
																onClick={() =>
																	submitPayment({
																		payment_type: couponCode
																			? 'coupon'
																			: 'zero_value',
																		amount: amountToPay,
																	})
																}
																disabled={loading}
																className='btn btn-primary mt-2'
															>
																{loading ? (
																	<i className='fas fa-circle-notch fa-spin'></i>
																) : (
																	'Checkout'
																)}
															</button>
														)}
													</div>
												</div>
											</div>
											{/* <!--end: Wizard Step 5--> */}

											{/* <!--begin: Wizard Actions--> */}
											<div className='d-flex justify-content-between border-top mt-5 pt-2 my-4'>
												<div>
													<button
														type='button'
														onClick={() => setNextButton(false)}
														className={`btn btn-primary font-weight-bolder text-uppercase px-5 py-2`}
														data-wizard-type='action-prev'
													>
														Previous
													</button>
												</div>
												<div>
													{/* <div>
														<button
															type='button'
															disabled={loading}
															className='btn btn-success font-weight-bolder text-uppercase px-3 py-2'
															data-wizard-type='action-submit'
														>
															Submit
														</button>
														{loading && (
															<div
																className='spinner-border spinner-border-sm text-light'
																role='status'
															>
																<span className='visually-hidden'>
																	Loading...
																</span>
															</div>
														)}
													</div> */}
													<div>
														<button
															type='button'
															disabled={nextButton}
															onClick={() => setNextButton(true)}
															className={`btn btn-primary font-weight-bolder text-uppercase px-3 py-2`}
															data-wizard-type='action-next'
														>
															Next{' '}
															{nextLoading && (
																<div
																	className='spinner-border spinner-border-sm text-light'
																	role='status'
																>
																	<span className='visually-hidden'>
																		Loading...
																	</span>
																</div>
															)}
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* <!--end: Wizard Body--> */}
								</div>
								{/* <!--end: Wizard--> */}
							</div>
						</div>
					</div>
					{/* <!--end::Container--> */}
				</div>
			</Modal.Body>
			<RequestModal
				profileData={profileData}
				onHide={() => setProfileData(false)}
			/>
		</>
	)
}
