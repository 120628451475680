import React from "react";
import { Modal } from "react-bootstrap";

export function BookingEditDialogHeader() {

  return (
    <>
      <Modal.Header className="py-5">
        <div className="container" style={{padding: 0}}>
          <Modal.Title id="example-modal-sizes-title-lg">Create New Booking</Modal.Title>
        </div>
      </Modal.Header>
    </>
  );
}
