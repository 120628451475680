import React from 'react'
import { Rating } from 'react-simple-star-rating'

export function RatingComponent(props) {
	const handleRating = (rate) => {
		props.setFieldValue(rate)
	}

	return (
		<div className='App'>
			<Rating
				initialValue={props.rating}
				onClick={handleRating}
				allowFraction={true}
				readonly={props.readOnly}
				showTooltip={false}
			/>
		</div>
	)
}
