import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL + '/open'

export function getServiceDetailsBySlug(user_slug) {
	return axios.get(`${API_URL}/servicelocations/${user_slug}`)
}

export function findLocations(business_slug) {
	if (business_slug)
		return axios.get(`${API_URL}/locations/?business_slug=${business_slug}`)
	else return axios.get(`${API_URL}/locations`)
}

export function getAllServices(queryParams) {
	return axios.get(
		`${API_URL}/services/?queryParams=${JSON.stringify(queryParams)}`
	)
}

export function findServiceLocations(queryParams) {
	return axios.get(
		`${API_URL}/servicelocations/?queryParams=${JSON.stringify(queryParams)}`
	)
}

export function getStaffAvailability(data) {
	return axios.post(`${API_URL}/timesheets/availability`, data)
}

export function checkStaffDateAvailabilities(queryParams) {
	return axios.get(
		`${API_URL}/checkAvailability/?queryParams=${JSON.stringify(queryParams)}`
	)
}

export function createABooking(data) {
	let config = {
		headers: {
			fullurl: window.location.href,
		},
	}
	return axios.post(`${API_URL}/booking`, data, config)
}

export function findSettings(queryParams) {
	return axios.get(
		`${API_URL}/settings/?queryParams=${JSON.stringify(queryParams)}`
	)
}

export function getOneCoupon(data) {
	return axios.post(`${API_URL}/checkCoupon/`, data)
}

export function findProfiles(queryParams = {}) {
	return axios.get(
		`${API_URL}/profile?queryParams=${JSON.stringify(queryParams)}`
	)
}

export function findTaxrates(queryParams = {}) {
	return axios.get(
		`${API_URL + '/taxrates'}/?queryParams=${JSON.stringify(queryParams)}`
	)
}

export function createRequest(data) {
	return axios.post(API_URL + '/request', data)
}
