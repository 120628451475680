import React from 'react'
import { createRequest } from '../Crud'
import { Modal } from 'react-bootstrap'
import dayjs from 'dayjs'
import * as Yup from 'yup'
import { ErrorMessage, Field, Formik } from 'formik'
import swal from 'sweetalert2'

const RequestSchema = Yup.object().shape({
	user_id: Yup.string().required().label('Mentor'),
	time: Yup.date().required().label('Suggested Time'),
	duration: Yup.string().required().label('Duration'),
	message: Yup.string().required().label('Message'),
	first_name: Yup.string().required().label('First Name'),
	last_name: Yup.string().required().label('Last Name'),
	email: Yup.string().email().required().label('Email'),
})
const RequestModal = ({ profileData, onHide }) => {
	return (
		<Modal size='lg' show={profileData ? true : false} onHide={onHide} centered>
			<Modal.Header>
				<Modal.Title>
					<span className='fw-bold'>Request to Open Calendar</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Formik
					enableReinitialize={true}
					initialValues={{
						business_id: profileData.business_id,
						user_id: profileData.user_id,
						duration: profileData.duration || 60,
						time: '',
						message: '',
						first_name: '',
						last_name: '',
						email: '',
					}}
					validationSchema={RequestSchema}
					onSubmit={async (values) => {
						try {
							const result = await createRequest(values)
							if (result.status !== 200) throw result
							swal.fire('Success', result.data.message, 'success')
							onHide()
						} catch (e) {
							swal.fire(
								'Error',
								e.response?.data?.message ||
									'Error creating data, kindly try again',
								'error'
							)
						}
					}}
				>
					{({ isSubmitting, handleSubmit }) => (
						<>
							<div className='container'>
								<div className='form-group row'>
									<div className='col-md-3 d-flex align-items-center'>
										<label className='font-weight-bolder'>First Name</label>
									</div>
									<div className='col-md-7'>
										<div className='input-group w-100'>
											<Field
												type='text'
												className='form-control'
												name='first_name'
											/>
										</div>
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<ErrorMessage name='first_name' />
											</div>
										</div>
									</div>
								</div>
								<div className='form-group row'>
									<div className='col-md-3 d-flex align-items-center'>
										<label className='font-weight-bolder'>Last Name</label>
									</div>
									<div className='col-md-7'>
										<div className='input-group w-100'>
											<Field
												type='text'
												className='form-control'
												name='last_name'
											/>
										</div>
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<ErrorMessage name='last_name' />
											</div>
										</div>
									</div>
								</div>
								<div className='form-group row'>
									<div className='col-md-3 d-flex align-items-center'>
										<label className='font-weight-bolder'>Email</label>
									</div>
									<div className='col-md-7'>
										<div className='input-group w-100'>
											<Field
												type='email'
												className='form-control'
												name='email'
											/>
										</div>
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<ErrorMessage name='email' />
											</div>
										</div>
									</div>
								</div>
								<div className='form-group row'>
									<div className='col-md-3 d-flex align-items-center'>
										<label className='font-weight-bolder'>
											Suggest Date & Time
										</label>
									</div>
									<div className='col-md-7'>
										<div className='input-group w-100'>
											<Field
												type='datetime-local'
												className='form-control'
												name='time'
												min={dayjs().format('YYYY-MM-DD HH:mm')}
											/>
										</div>
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<ErrorMessage name='time' />
											</div>
										</div>
									</div>
								</div>
								<div className='form-group row'>
									<div className='col-md-3 d-flex align-items-center'>
										<label className='font-weight-bolder'>
											Duration in Minutes
										</label>
									</div>
									<div className='col-md-7'>
										<div className='input-group w-100'>
											<Field
												type='number'
												className='form-control'
												name='duration'
											/>
										</div>
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<ErrorMessage name='duration' />
											</div>
										</div>
									</div>
								</div>
								<div className='form-group row'>
									<div className='col-md-3 d-flex align-items-center'>
										<label className='font-weight-bolder'>Message</label>
									</div>
									<div className='col-md-7'>
										<div className='input-group w-100'>
											<Field
												as='textarea'
												type='text'
												className='form-control'
												name='message'
											/>
										</div>
										<div className='fv-plugins-message-container'>
											<div className='fv-help-block'>
												<ErrorMessage name='message' />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='d-flex justify-content-between border-top mt-5 pt-2 my-4'>
								<div></div>
								<div>
									<button
										type='button'
										// disabled={nextButton || loading}
										className='btn btn-success font-weight-bolder text-uppercase px-3 py-2'
										data-wizard-type='action-submit'
										onClick={handleSubmit}
									>
										Submit
									</button>
									{isSubmitting && (
										<div
											className='spinner-border spinner-border-sm text-light'
											role='status'
										>
											<span className='visually-hidden'>Loading...</span>
										</div>
									)}
								</div>
							</div>
						</>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	)
}
export default RequestModal
