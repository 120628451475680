import React from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../components/Helpers'

export function Navbar() {
	return (
		<>
			<nav className='navbar navbar-expand-lg navbar-light bg-white'>
				<div className='container-fluid'>
					{/* <!-- Brand --> */}
					<Link className='navbar-brand' to='/'>
						<img
							src={toAbsoluteUrl('/assets/img/logos/BookMe-blue.png')}
							className='navbar-brand-img'
							alt='...'
						/>
					</Link>

					{/* <!-- Toggler --> */}
					<button
						className='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarCollapse'
						aria-controls='navbarCollapse'
						aria-expanded='false'
						aria-label='Toggle navigation'
					>
						<span className='navbar-toggler-icon'></span>
					</button>

					{/* <!-- Collapse --> */}
					<div className='collapse navbar-collapse' id='navbarCollapse'>
						{/* <!-- Toggler --> */}
						<button
							className='navbar-toggler'
							type='button'
							data-bs-toggle='collapse'
							data-bs-target='#navbarCollapse'
							aria-controls='navbarCollapse'
							aria-expanded='false'
							aria-label='Toggle navigation'
						>
							<i className='fe fe-x'></i>
						</button>

						{/* <!-- Button --> */}
						{/* <div className='ms-auto'> */}
						<ul className='navbar-nav ms-auto me-lg-3 fs-lg'>
							<li className='nav-item '>
								<Link className='nav-link' id='menu-1' to='/'>
									Make a booking
								</Link>
							</li>
							<li className='nav-item '>
								<Link className='nav-link' id='menu-1' to='/mentors'>
									View Mentors
								</Link>
							</li>
							<li className='nav-item '>
								<a
									className='nav-link'
									id='menu-1'
									href='https://singlelogin.io/schedule-demo'
								>
									Contact Us
								</a>
							</li>
							<li className='nav-item '>
								<a
									className='nav-link'
									id='menu-1'
									href='https://singlelogin.io/schedule-demo'
								>
									About
								</a>
							</li>
							<li className='nav-item '>
								<a
									className='nav-link'
									id='menu-1'
									href='https://singlelogin.io/schedule-demo'
								>
									FAQs
								</a>
							</li>
							<>
								<div className='header-btn '>
									<a
										href={process.env.REACT_APP_URL}
										className='btn btn-primary btn-sm '
									>
										Sign in
									</a>
								</div>
							</>
						</ul>
						{/* <a
								className='navbar-btn btn btn-sm btn-primary lift me-2'
								href={process.env.REACT_APP_URL}
							>
								Sign In
							</a> */}
						{/* </div> */}
						{/* <a className="navbar-btn btn btn-sm btn-success lift ms-2" data-bs-toggle="modal" href="#modalExample">
                Make a Booking
              </a> */}
					</div>
				</div>
			</nav>
		</>
	)
}
