import React from 'react'

export function Footer() {
	return (
		<section>
			<footer className='py-8 py-md-11 bg-dark border-top border-gray-800-50 fs-lg'>
				<div className='container'>
					<div className='row'>
						<div className='col-12 col-md-4 col-lg-3'>
							{/* <!-- Brand --> */}
							<span className='text-white h2'>BookMe.</span>
							{/* <img
								src='/assets/img/logos/bookme-white.png'
								alt='...'
								className='footer-brand img-fluid mb-2'
							/> */}
							{/* <!-- Text --> */}
							<p className='text-gray-700 mb-2 fs-sm'>
								Powered by SingleLogin.io.
							</p>
							{/* <!-- Social --> */}
							<ul className='list-unstyled list-inline list-social mb-6 mb-md-0'>
								<li className='list-inline-item list-social-item me-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-decoration-none'
									>
										<img
											src='/assets/img/icons/social/instagram.svg'
											className='list-social-icon'
											alt='...'
										/>
									</a>
								</li>
								<li className='list-inline-item list-social-item me-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-decoration-none'
									>
										<img
											src='/assets/img/icons/social/facebook.svg'
											className='list-social-icon'
											alt='...'
										/>
									</a>
								</li>
								<li className='list-inline-item list-social-item me-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-decoration-none'
									>
										<img
											src='/assets/img/icons/social/twitter.svg'
											className='list-social-icon'
											alt='...'
										/>
									</a>
								</li>
								<li className='list-inline-item list-social-item'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-decoration-none'
									>
										<img
											src='/assets/img/icons/social/pinterest.svg'
											className='list-social-icon'
											alt='...'
										/>
									</a>
								</li>
							</ul>
						</div>
						<div className='col-6 col-md-4 col-lg-2'>
							{/* <!-- Heading --> */}
							<h6 className='fw-bold text-uppercase text-gray-700'>Pages</h6>

							{/* <!-- List --> */}
							<ul className='list-unstyled text-muted mb-6 mb-md-8 mb-lg-0'>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										Pricing
									</a>
								</li>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										Help Center
									</a>
								</li>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										Schedule a Demo
									</a>
								</li>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										Why Single Login
									</a>
								</li>
							</ul>
						</div>
						<div className='col-6 col-md-4 col-lg-2'>
							{/* <!-- Heading --> */}
							<h6 className='fw-bold text-uppercase text-gray-700'>Services</h6>

							{/* <!-- List --> */}
							<ul className='list-unstyled text-muted mb-6 mb-md-8 mb-lg-0'>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										Enterprise
									</a>
								</li>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										Business
									</a>
								</li>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										Developers
									</a>
								</li>
							</ul>
						</div>
						<div className='col-6 col-md-4 offset-md-4 col-lg-2 offset-lg-0'>
							{/* <!-- Heading --> */}
							<h6 className='fw-bold text-uppercase text-gray-700'>
								Applications
							</h6>

							{/* <!-- List --> */}
							<ul className='list-unstyled text-muted mb-0'>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										Timesheet
									</a>
								</li>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										eLearning
									</a>
								</li>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										Expense App
									</a>
								</li>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										Quiz App
									</a>
								</li>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										Scheduling
									</a>
								</li>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										SalesBook
									</a>
								</li>
								<li>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										Events
									</a>
								</li>
							</ul>
						</div>
						<div className='col-6 col-md-4 col-lg-2'>
							{/* <!-- Heading --> */}
							<h6 className='fw-bold text-uppercase text-gray-700'>Legal</h6>

							{/* <!-- List --> */}
							<ul className='list-unstyled text-muted mb-0'>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										Documentation
									</a>
								</li>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										Versions
									</a>
								</li>
								<li className='mb-3'>
									<a
										href='https://singlelogin.io/schedule-demo'
										className='text-reset'
									>
										API Status
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>{' '}
				{/* <!-- / .container --> */}
			</footer>
		</section>
	)
}
