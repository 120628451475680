import React from 'react'
import { Modal } from 'react-bootstrap'
import { BookingEditDialogHeader } from './BookingEditDialogHeader'
import { UserBookingEditDialogHeader } from './userServiceLocationBooking/UserServiceLocationBookingEditDialogHeader'
import { WizardPage } from './wizardPage'
import { UserWizardPage } from './userServiceLocationBooking/userServiceLocationWizardPage'
import Swal from 'sweetalert2'

export function BookingEditDialog({
	business_slug,
	user_slug,
	details,
	user,
	actionLoading,
	show,
	setShow,
	error,
}) {
	const hide = () => {
		setShow(false)
	}
	const invalid_url = () => {
		hide()
		Swal.fire(
			'Oops',
			error || 'Invalid Url! Please contact the administrator',
			'error',
		)
	}

	return (
		<>
			<Modal
				size='xl'
				show={show}
				onHide={hide}
				aria-labelledby='example-modal-sizes-title-lg'
				centered
			>
				{user_slug ? (
					actionLoading === true ? (
						<div
							className='spinner-border m-auto my-5'
							style={{ width: '3rem', height: '3rem' }}
							role='status'
						>
							<span className='visually-hidden'>Loading...</span>
						</div>
					) : !details ? (
						invalid_url()
					) : (
						<>
							<UserBookingEditDialogHeader details={details} />
							<UserWizardPage onHide={hide} details={details} />
						</>
					)
				) : (
					<>
						<BookingEditDialogHeader />
						<WizardPage
							business_slug={business_slug}
							user={user}
							onHide={hide}
						/>
					</>
				)}
			</Modal>
		</>
	)
}
