import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Modal } from 'react-bootstrap'
import { WizardControl } from '../wizard_control'
import '../wizard-3.css'
import TimeButton from '../timeButton'
import DateButton from '../dateButton'
import {
	getStaffAvailability,
	findSettings,
	createABooking,
	checkStaffDateAvailabilities,
	getOneCoupon,
} from '../../../Crud'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress, colors } from '@material-ui/core'
import ScrollArea from 'react-scrollbar'
import '../_wizard.scss'
import * as Yup from 'yup'
import { usePaystackPayment } from 'react-paystack'
import Swal from 'sweetalert2'

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
	wrapper: {
		margin: '1px', //theme.spacing(1),
		position: 'relative',
	},
	buttonProgress: {
		color: colors.green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	nextButtonProgress: {
		color: 'white',
		position: 'absolute',
	},
}))

export function UserWizardPage({ details, onHide }) {
	const classes = useStyles()
	const [nextButton, setNextButton] = useState(true)
	const [nextLoading, setNextLoading] = useState(false)
	const [booking, setBooking] = useState(details)
	const [times, setTimes] = useState([])
	const [availableDates, setAvailableDates] = useState()
	const [settings, setSettings] = useState({})
	const [loading, setLoading] = useState(false)
	const [couponPrice, setCouponPrice] = useState()
	const [couponCode, setCouponCode] = useState()
	const [couponLoading, setCouponLoading] = useState(false)
	const [amountToPay, setAmountToPay] = useState()
	const customer_details = {
		firstname: '',
		lastname: '',
		email: '',
		phone_number: '',
		coupon_code: '',
	}

	const schema = Yup.object().shape({
		firstname: Yup.string()
			.min(3, 'Minimum 3 symbols')
			.max(25, 'Maximum 25 symbols')
			.required('Required Field'),
		email: Yup.string().email('Invalid email').required('Email is required'),
		lastname: Yup.string()
			.min(3, 'Minimum 3 symbols')
			.max(25, 'Maximum 25 symbols'),
		phone_number: Yup.string(),
	})

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: customer_details,
		validationSchema: schema,
		onSubmit: (values) => {
			if (values.coupon_code) {
				values.business_id = booking.business_id
				setCouponLoading(true)
				getOneCoupon(values)
					.then((response) => {
						if (response.status === 200) {
							const amt_to_pay = amountToPay - response.data.data.price
							setAmountToPay(amt_to_pay > 0 ? amt_to_pay : 0)
							setCouponPrice(response.data.data.price)
							setCouponCode(response.data.data.id)
						} else throw response
					})
					.catch(({ response }) => {
						Swal.fire(
							'error',
							response.data?.message || 'Error validating the coupon code',
							'error'
						)
						setCouponLoading(false)
					})
			}
		},
	})

	useEffect(() => {
		setAmountToPay(details.charge)
	}, [details])
	useEffect(() => {
		const queryParams = {
			filter: {
				staff_id: details.staff_id,
				business_id: details.business_id,
			},
		}
		setTimes([])
		checkStaffDateAvailabilities(queryParams).then(({ data: { data } }) => {
			setAvailableDates(data)
		})
	}, [details])
	const getAvailTIme = (date) => {
		setBooking({ ...booking, date: moment(date).format('L') })
		setNextLoading(true)
		getStaffAvailability({
			date: moment(date).format('L'),
			staff_id: booking.staff_id,
			duration: booking.duration,
			business_id: booking.business_id,
		})
			.then(({ data: { data } }) => {
				let staff_availabities = []
				data.map((item, index) => {
					staff_availabities.push({
						id: index,
						start_time: moment.utc(item.start_time).format('h:mm a'),
						end_time: moment.utc(item.end_time).format('h:mm a'),
					})
					return true
				})
				setTimes(staff_availabities)
				setNextLoading(false)
				const query = {
					business_id: booking.business_id,
					filter: [
						'STRIPE_PUBLISHABLE_KEY',
						'PAYSTACK_PUBLIC_KEY',
						'CURRENCY',
						'TIMEZONE',
					],
				}
				findSettings(query).then((response) => setSettings(response.data.data))
			})
			.catch(({ response }) => {
				console.log(response)
			})
	}
	const setTime = (start, end) => {
		setBooking({ ...booking, start_time: start, end_time: end })
		setNextButton(false)
	}
	//paystack implementation
	const config = {
		reference: new Date().getTime(),
		email: formik.values.email,
		amount: amountToPay * 100,
		publicKey: settings?.PAYSTACK_PUBLIC_KEY,
	}

	const submitPayment = (payment) => {
		const customer = {
			firstname: formik.values.firstname,
			lastname: formik.values.lastname,
			email: formik.values.email,
			phone_number: formik.values.phone_number,
		}
		booking.customer = customer
		if (couponCode) payment.coupon_id = couponCode
		setLoading(true)
		createABooking({ booking, payment })
			.then((response) => {
				setLoading(false)
				if (response.data?.data?.url)
					window.location.href = response.data.data.url
				else {
					onHide()
					Swal.fire(
						'Congratulations',
						response.data.message + '. Details has been sent to you E-mail',
						'success'
					)
				}
			})
			.catch(({ response }) => {
				setLoading(false)
				Swal.fire('Oops', response.data.message, 'error')
			})
	}
	const onSuccess = (reference) => {
		// Implementation for whatever you want to do with reference and after success call.
		let data = {
			id: booking.id,
			reference,
			payment_type: 'paystack',
		}
		setLoading(true)
		submitPayment(data)
	}
	// you can call this function anything
	const onClose = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
		console.log('closed')
	}
	const initializePayment = usePaystackPayment(config)

	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return 'is-invalid'
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return 'is-valid'
		}

		return ''
	}
	return (
		<>
			<Modal.Body className='overlay overlay-block cursor-default py-0'>
				{loading && (
					<div className='overlay-layer bg-transparent'>
						<div className='spinner spinner-lg spinner-success' />
					</div>
				)}
				<WizardControl />
				<div className='d-flex flex-column-fluid'>
					{/* <!--begin::Container--> */}
					<div className='container'>
						<div className='card card-custom'>
							<div className='card-body p-0'>
								{/* <!--begin: Wizard--> */}
								<div
									className='wizard wizard-3'
									id='kt_wizard_v3'
									data-wizard-state='step-first'
								>
									{/* <!--begin: Wizard Nav--> */}
									<div className='wizard-nav'>
										<div className='wizard-steps px-8 py-8 px-lg-5 py-lg-1'>
											{/* <!--begin::Wizard Step 1 Nav--> */}
											<div className='wizard-step' data-wizard-type='step'>
												<div className='wizard-label'>
													<h3 className='wizard-title'>
														<span>1.</span>Date & Time
													</h3>
													<div className='wizard-bar'></div>
												</div>
											</div>
											{/* <!--end::Wizard Step 4 Nav--> */}
											{/* <!--begin::Wizard Step 5 Nav--> */}
											<div className='wizard-step' data-wizard-type='step'>
												<div className='wizard-label'>
													<h3 className='wizard-title'>
														<span>2.</span>Summary
													</h3>
													<div className='wizard-bar'></div>
												</div>
											</div>
											{/* <!--end::Wizard Step 5 Nav--> */}
											{/* <!--begin::Wizard Step 5 Nav--> */}
											<div className='wizard-step' data-wizard-type='step'>
												<div className='wizard-label'>
													<h3 className='wizard-title'>
														<span>3.</span>Payment
													</h3>
													<div className='wizard-bar'></div>
												</div>
											</div>
											{/* <!--end::Wizard Step 5 Nav--> */}
										</div>
									</div>
									{/* <!--end: Wizard Nav--> */}
									{/* <!--begin: Wizard Body--> */}
									<div className='row justify-content-center py-2 px-8 px-lg-5'>
										<div className='col-xl-12 col-xxl-12'>
											{/* <!--begin: Wizard Form--> */}
											{/* <form className="form" id="kt_form"> */}
											{/* <!--begin: Wizard Step 1--> */}
											<div className='pb-5' data-wizard-type='step-content'>
												<h4 className='mb-2 font-weight-bold text-dark'>
													Select Your Preferred Date and Time
												</h4>
												<ScrollArea style={{ maxHeight: '350px' }}>
													<div className='row'>
														<div className='col-lg-6'>
															{availableDates && (
																<DateButton
																	getAvailTIme={getAvailTIme}
																	availableDates={availableDates}
																/>
															)}
														</div>
														<div className='col-lg-6'>
															<TimeButton data={times} setData={setTime} />
															{booking.date && times.length === 0 && (
																<div className='container my-3'>
																	<strong>Period(s): Unavailable!!! </strong>
																	Please select a new date.{' '}
																</div>
															)}
														</div>
													</div>
												</ScrollArea>
											</div>
											{/* <!--end: Wizard Step 1--> */}
											{/* <!--begin: Wizard Step 2--> */}
											<div className='pb-5' data-wizard-type='step-content'>
												<ScrollArea style={{ maxHeight: '300px' }}>
													<div className='container'>
														<h4 className='mb-2 font-weight-bold text-dark text-center mb-5'>
															Confirm Your Booking Summary
														</h4>
														<div
															className='row fw-bolder'
															style={{ fontSize: '16px' }}
														>
															<div className='col-lg-6 mb-5'>
																<div className='row'>
																	<div className='text-muted col-3'>
																		Location:
																	</div>
																	<div className='font-weight-bold col-9'>
																		{details.location}
																	</div>
																</div>
																<div className='row'>
																	<div className='text-muted col-3'>
																		Service:
																	</div>
																	<div className='font-weight-bold col-9'>
																		{details.service}
																	</div>
																</div>
																<div className='row'>
																	<div className='text-muted col-3'>Date:</div>
																	<div className='font-weight-bold col-8'>
																		{booking.date}
																	</div>
																</div>
																<div className='row'>
																	<div className='text-muted col-3'>Staff:</div>
																	<div className='font-weight-bold col-8'>
																		{details.staff_name}
																	</div>
																</div>
																<div className='row'>
																	<div className='text-muted col-3'>
																		Timezone:
																	</div>
																	<div className='font-weight-bold col-8'>
																		{settings.TIMEZONE}
																	</div>
																</div>
															</div>
															<div className='col-lg-6 mb-5'>
																<div className='row'>
																	<div
																		className='text-muted col-4'
																		style={{ textAlign: 'right' }}
																	>
																		Start Time:
																	</div>
																	<div className='font-weight-bold col-8'>
																		{booking.start_time}
																	</div>
																</div>
																<div className='row'>
																	<div
																		className='text-muted col-4'
																		style={{ textAlign: 'right' }}
																	>
																		End Time:
																	</div>
																	<div className='font-weight-bold col-8'>
																		{booking.end_time}
																	</div>
																</div>
																<div className='row'>
																	<div
																		className='text-muted col-4'
																		style={{ textAlign: 'right' }}
																	>
																		Total Charge:
																	</div>
																	<div className='font-weight-bold col-8'>
																		{settings.CURRENCY}
																		{details.charge}
																	</div>
																</div>
																<div className='row'>
																	<div
																		className='text-muted col-4'
																		style={{ textAlign: 'right' }}
																	>
																		Amount to Pay:
																	</div>
																	<div className='font-weight-bold col-8'>
																		{settings.CURRENCY}
																		{amountToPay}
																	</div>
																</div>
															</div>
														</div>
														<hr />
														<div className='form-check'>
															<input
																className='form-check-input'
																onChange={(e) =>
																	e.target.checked
																		? setNextButton(false)
																		: setNextButton(true)
																}
																type='checkbox'
																id='flexCheckDefault'
															/>
															<label
																className='form-check-label'
																for='flexCheckDefault'
															>
																I acknowlege that the details above is correct
															</label>
														</div>
													</div>
												</ScrollArea>
											</div>
											{/* <!--end: Wizard Step 2--> */}
											{/* <!--begin: Wizard Step 3--> */}
											<div className='pb-5' data-wizard-type='step-content'>
												<div className='row'>
													<div className='row'>
														<div className='col-6'>
															<label>Enter First Name</label>
															<input
																{...formik.getFieldProps('firstname')}
																type='text'
																className={`form-control mb-5 ${getInputClasses(
																	'firstname'
																)}`}
																placeholder='Please type your first name* (Required)'
															/>
														</div>
														<div className='col-6'>
															<label>Enter Last Name</label>
															<input
																{...formik.getFieldProps('lastname')}
																type='text'
																className={`form-control mb-5 ${getInputClasses(
																	'lastname'
																)}`}
																placeholder='Please type your last name'
															/>
														</div>
														<div className='col-6'>
															<label>Enter Email</label>
															<input
																{...formik.getFieldProps('email')}
																type='email'
																className={`form-control mb-5 ${getInputClasses(
																	'email'
																)}`}
																placeholder='Please type a valid email address* (Required)'
															/>
														</div>
														<div className='col-6'>
															<label>Enter Phone</label>
															<input
																{...formik.getFieldProps('phone_number')}
																type='text'
																className={`form-control mb-5 ${getInputClasses(
																	'phone_number'
																)}`}
																placeholder='Please type your phone number'
															/>
														</div>
														<div className='container'>
															<div className='form-group row'>
																<div
																	className='col-md-3 d-flex align-items-center'
																	style={{ textAlign: 'right' }}
																>
																	<label className='font-weight-bolder position-absolute right-0'>
																		Apply Coupon
																	</label>
																</div>
																<div className='col-md-7'>
																	<div className='input-group'>
																		<input
																			{...formik.getFieldProps('coupon_code')}
																			type='text'
																			className={`form-control ${getInputClasses(
																				'coupon_code'
																			)}`}
																			placeholder='Coupon Code'
																		/>
																		<div className='input-group-append'>
																			<button
																				className='btn btn-secondary'
																				type='button'
																				disabled={couponLoading}
																				onClick={formik.handleSubmit}
																			>
																				{couponPrice ? (
																					<span className='fe fe-check'></span>
																				) : couponLoading ? (
																					<div
																						className='spinner-border text-primary'
																						role='status'
																					>
																						<span className='visually-hidden'>
																							Loading...
																						</span>
																					</div>
																				) : (
																					`Apply`
																				)}
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													{/* {getInputClasses("firstname") === 'is-valid' && getInputClasses("email") === 'is-valid' && */}
													<div className='row justify-content-center align-items-center mt-5'>
														<h4 className='mb-3 font-weight-bold text-dark text-center'>
															Choose a payment method: {settings.CURRENCY}
															{amountToPay}
														</h4>
														{amountToPay > 0 &&
															settings?.STRIPE_PUBLISHABLE_KEY &&
															settings?.STRIPE_PUBLISHABLE_KEY !== '' && (
																<div className='col-md-4 text-center'>
																	<img
																		onClick={() =>
																			formik.values.email &&
																			getInputClasses('email') !==
																				'is-invalid' &&
																			formik.values.firstname &&
																			getInputClasses('firstname') !==
																				'is-invalid' &&
																			submitPayment({
																				payment_type: 'stripe',
																				amount: amountToPay,
																			})
																		}
																		style={{ width: '5rem', cursor: 'pointer' }}
																		alt='Stripe'
																		src='/assets/img/logos/stripe_logo.png'
																	></img>
																</div>
															)}
														{amountToPay > 0 &&
															settings?.PAYSTACK_PUBLIC_KEY &&
															settings?.PAYSTACK_PUBLIC_KEY !== '' && (
																<div className='col-md-4 text-center'>
																	<img
																		onClick={() =>
																			formik.values.email &&
																			getInputClasses('email') !==
																				'is-invalid' &&
																			formik.values.firstname &&
																			getInputClasses('firstname') !==
																				'is-invalid' &&
																			initializePayment(onSuccess, onClose)
																		}
																		style={{ width: '5rem', cursor: 'pointer' }}
																		alt='Paystack'
																		src='/assets/img/logos/paystack_logo.png'
																	></img>
																</div>
															)}
														{amountToPay === 0 && (
															<button
																type='button'
																onClick={() =>
																	submitPayment({
																		payment_type: couponCode
																			? 'coupon'
																			: 'zero_value',
																		amount: amountToPay,
																	})
																}
																disabled={loading}
																className='btn btn-primary mt-2'
															>
																{loading ? (
																	<i className='fas fa-circle-notch fa-spin'></i>
																) : (
																	'Checkout'
																)}
															</button>
														)}
													</div>
												</div>
											</div>
											{/* <!--end: Wizard Step 3--> */}
											{/* <!--begin: Wizard Actions--> */}
											<div className='d-flex justify-content-between border-top mt-5 pt-2 my-4'>
												{/* <div className="mr-2">
													<button type="button" onClick={() => setNextButton(false)} className={`btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4`} data-wizard-type="action-prev">Previous</button>
												</div> */}
												<div className={classes.root}>
													<div className={classes.wrapper}>
														<button
															type='button'
															onClick={() => setNextButton(false)}
															className={`btn btn-primary font-weight-bolder text-uppercase px-5 py-2`}
															data-wizard-type='action-prev'
														>
															Previous
														</button>
													</div>
												</div>
												<div>
													<div className={classes.root}>
														<div className={classes.wrapper}>
															<button
																type='button'
																disabled={nextButton || loading}
																className='btn btn-success font-weight-bolder text-uppercase px-5 py-2'
																data-wizard-type='action-submit'
															>
																Submit
															</button>
															{loading && (
																<CircularProgress
																	size={24}
																	className={classes.buttonProgress}
																/>
															)}
														</div>
													</div>
													<div className={classes.root}>
														<div className={classes.wrapper}>
															<button
																type='button'
																disabled={nextButton}
																onClick={() => setNextButton(true)}
																className={`btn btn-primary font-weight-bolder text-uppercase px-5 py-2`}
																data-wizard-type='action-next'
															>
																Next{' '}
																{nextLoading && (
																	<CircularProgress
																		size={22}
																		className={classes.nextButtonProgress}
																	/>
																)}
															</button>
														</div>
													</div>
												</div>
											</div>
											{/* <!--end: Wizard Actions--> */}
											{/* </form> */}
											{/* <!--end: Wizard Form--> */}
										</div>
									</div>
									{/* <!--end: Wizard Body--> */}
								</div>
								{/* <!--end: Wizard--> */}
							</div>
						</div>
					</div>
					{/* <!--end::Container--> */}
				</div>
			</Modal.Body>
		</>
	)
}
