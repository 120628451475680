import React from "react";
import { Modal } from "react-bootstrap";

export function UserBookingEditDialogHeader({details}) {

  return (
    <>
      <Modal.Header className="py-5">
        <div className="container" style={{padding: 0}}>
          <Modal.Title id="example-modal-sizes-title-lg">
                <span className="fw-bold">
                  Create New Booking With <span className="text-primary">{details.staff_name} </span>
                   for <span className="text-primary">{details.service} </span>
                  at <span className="text-primary">{details.location} </span>
                </span>
            {/* Create New Booking With <b>{details.staff_name}</b> for <b>{details.service}</b> at <b>{details.location}</b> */}
          </Modal.Title>
        </div>
      </Modal.Header>
    </>
  );
}
