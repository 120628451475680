import React, { useState } from 'react'
import { Card, Col } from 'react-bootstrap'

export default function TitlebarGridList({ setData, data }) {
	const [tileClick, setTileClick] = useState('')
	function processData(tile) {
		setTileClick(tile.id)
		setData(tile)
	}
	return data.length > 0 ? (
		data.map((tile) => (
			<Col key={tile.id} sm={12} md={6} lg={3} xl={3}>
				<Card
					className='my-3 p-3 rounded'
					onClick={() => processData(tile)}
					style={{ cursor: 'pointer' }}
				>
					{tile.img_url ? (
						<Card.Img
							src={tile.img_url}
							variant='top'
							style={
								tile.id === tileClick ? { opacity: '0.5' } : { opacity: '1' }
							}
						/>
					) : (
						<div
							className='avatar avatar-xxl'
							style={
								tile.id === tileClick
									? {
											opacity: '0.5',
											margin: 'auto',
											height: '120px',
											width: '120px',
									  }
									: {
											opacity: '1',
											margin: 'auto',
											height: '120px',
											width: '120px',
									  }
							}
						>
							<span className='avatar-title rounded'>
								{tile.name?.split(' ')[0][0].toUpperCase() ||
									tile.staff_name?.split(' ')[0][0].toUpperCase()}
							</span>
						</div>
					)}
					<Card.Body className='py-1 px-2 text-center'>
						<span>
							<Card.Title as='div' className='my-1'>
								<strong>{tile.name || tile.staff_name}</strong>
							</Card.Title>
						</span>

						<Card.Text as='div'>
							<div>
								{tile.city
									? tile.city + ', ' + tile.state + ' State'
									: tile.duration_in_min
									? 'Duration: ' + tile.duration_in_min + ' mins'
									: null}
							</div>
						</Card.Text>
					</Card.Body>
				</Card>
			</Col>
		))
	) : (
		<div className='container'>
			This Mentor does not have any availability set
		</div>
	)
}
