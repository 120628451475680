import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { LayoutSplashScreen } from './components/SplashScreen'
import { Navbar } from './components/Navbar'
import { Footer } from './components/Footer'
import { LandingPage } from './pages/LandingPage'
import MentorsPage from './pages/MentorsPage'

export function Routes() {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Navbar />
			<Switch>
				<Route path='/mentors' component={MentorsPage} />
				<Route path='/booking/:service_location_slug' component={LandingPage} />
				<Route path='/user/:user_id/:name' component={LandingPage} />
				<Route path='/business/:business_slug' component={LandingPage} />
				<Route path='/' component={LandingPage} />
			</Switch>
			<Footer />
		</Suspense>
	)
}
